var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "govuk-table" }, [
    _c("thead", { staticClass: "govuk-table__head" }, [_vm._t("header")], 2),
    _c("tbody", { staticClass: "govuk-table__body" }, [_vm._t("body")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }